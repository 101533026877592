import React from 'react';
import { Result, Button } from 'antd';

import Header from '../components/Header';

const PageComingSoon = (props) => (
  <>
    <Header noLogout={true} />
    <Result
      status="403"
      title="Coming Soon"
      subTitle="The item has not been launched yet. Please check again later."
      extra={
        <Button type="primary" onClick={() => (window.location = 'https://mathongo.com')}>
          Back Home
        </Button>
      }
    />
  </>
);

export default PageComingSoon;
