import { configureStore } from '@reduxjs/toolkit';

import uiSlice from './reducers/ui-slice';
import homeSlice from './reducers/home-slice';
import checkoutSlice from './reducers/checkout-slice';

const store = configureStore({
  reducer: { ui: uiSlice.reducer, home: homeSlice.reducer, checkout: checkoutSlice.reducer },
});

export default store;
