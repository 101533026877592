import Countries from 'country-state-city/lib/assets/country.json';
import States from 'country-state-city/lib/assets/state.json';
import Cities from 'country-state-city/lib/assets/city.json';

const compareFn = (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0);

export default Countries;

const getStatesOfCountry = (countryCode) => {
  if (!countryCode) return [];
  const states = States.filter((value) => value.countryCode === countryCode);
  return states.length > 0 ? states.sort(compareFn) : null;
};

const getCitiesOfState = (countryCode, stateCode) => {
  if (!countryCode || !stateCode) return [];
  const cities = Cities.filter((value) => value.countryCode === countryCode && value.stateCode === stateCode);
  return cities.length > 0 ? cities.sort(compareFn) : null;
};

const getMatchingState = (data, stateVal) => (data ? data.find((st) => st.name === stateVal) : null);

export { getStatesOfCountry, getCitiesOfState, getMatchingState };
