import React, { useEffect } from 'react';
import { Spin } from 'antd';

const CCAvenueForm = ({ formActionUrl, encryptedData, access_code }) => {
  useEffect(() => {
    setTimeout(() => {
      document.redirect.submit();
    }, 500);
  }, []);

  return (
    <>
      <Spin size="large" className="loadingSpinner" />

      <form id="nonseamless" name="redirect" method="post" action={formActionUrl}>
        <input type="hidden" id="encRequest" name="encRequest" value={encryptedData} />
        <input type="hidden" id="access_code" name="access_code" value={access_code} />
      </form>
    </>
  );
};

export default CCAvenueForm;
