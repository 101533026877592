import React from 'react';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom pt-2 pb-3">
      <div className="container-fluid">
        <a className="navbar-brand mx-auto" href="https://www.mathongo.com">
          <img src="https://www.mathongo.com/assets/img/logo-dark.svg" className="navbar-brand-img logo" alt="logo" />
        </a>
      </div>
    </nav>
  );
};

export default Header;
