import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Checkout from '../components/Checkout';
import { homeActions } from '../store/reducers/home-slice';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Home = () => {
  const query = useQuery();
  const dispatch = useDispatch();

  const item_id = query.get('item_id'),
    coupon = query.get('coupon'),
    source = query.get('utm_source'),
    medium = query.get('utm_medium'),
    campaign = query.get('utm_campaign'),
    term = query.get('utm_term'),
    name = query.get('name'),
    email = query.get('email'),
    phone = query.get('phone'),
    confirmEmail = query.get('confirmEmail'),
    redirectUrl = query.get('redirectUrl');

  useEffect(() => {
    dispatch(
      homeActions.initialize({
        item_id,
        coupon,
        utm: { source, medium, campaign, term },
        user: { name, email, phone, confirmEmail, redirectUrl },
      }),
    );
  }, [dispatch, campaign, coupon, email, item_id, medium, name, phone, confirmEmail, source, term, redirectUrl]);

  return <Checkout itemId={item_id} />;
};

export default Home;
