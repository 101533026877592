import axios from 'axios';
import { lowerFirst } from 'lodash';
import * as crypto from 'crypto';

import { uiActions } from '../reducers/ui-slice';
import { checkoutActions } from '../reducers/checkout-slice';

const api = process.env.REACT_APP_API_URL;

const customErrorObject = (message, extra) => ({
  response: { data: { error: true, message, extra } },
});

export const fetchItem = (item_id) => {
  return async (dispatch) => {
    dispatch(checkoutActions.toggleLoading(true));

    const fetchData = async (item_id) => {
      const response = await axios.get(`${api}/item/${item_id}`);
      const { error, item } = response.data;

      if (error) throw new Error('Error fetching item, refresh!');
      else {
        if (item !== {} && item.enabled) {
          return { item, loading: false };
        } else {
          return { item: undefined, loading: false };
        }
      }
    };

    try {
      const itemData = await fetchData(item_id);
      dispatch(checkoutActions.initalizeItem({ item: itemData.item || undefined, loading: itemData.loading || false }));
    } catch (error) {
      const errorResponse = error?.response?.data;
      if (errorResponse?.type === 'itemComingSoon') {
        dispatch(checkoutActions.initalizeItem({ item: undefined, loading: false, isComingSoon: true }));
        dispatch(uiActions.showNotification({ status: 'error', message: 'Item is coming soon!' }));
      } else {
        dispatch(checkoutActions.initalizeItem({ item: undefined, loading: false, isComingSoon: false }));
        dispatch(uiActions.showNotification({ status: 'error', message: 'Error fetching item, refresh!' }));
      }
    }
    dispatch(checkoutActions.updateCCAForm(null));
  };
};

export const initalCouponCheck = (coupon, itemId, originalPrice, email, mobile) => {
  return async (dispatch) => {
    const couponCode = coupon.toUpperCase();

    try {
      dispatch(checkoutActions.updateCoupon({ coupon: couponCode }));

      const { data } = await axios
        .get(`${api}/coupon/check`, {
          params: { coupon: couponCode, item_id: itemId, email, mobile },
        })
        .catch((err) => {
          const errObj = err.response.data;
          throw new Error(JSON.stringify(customErrorObject(errObj.message, { isCouponReferral: errObj.isReferral })));
        });

      const { error, message, discount, isReferral } = data;

      if (error) throw new Error(JSON.stringify(customErrorObject(message, { isCouponReferral: isReferral })));
      else {
        dispatch(
          checkoutActions.couponCheck({
            error: false,
            message,
            coupon: couponCode,
            originalPrice,
            discountedPrice: originalPrice - discount,
            lock: true,
            isCouponReferral: isReferral,
          }),
        );
      }
    } catch (err) {
      const parseError = JSON.parse(err.message);
      const { response } = parseError;

      if (response && response.data) {
        const { error, message, extra } = response.data;

        if (error) {
          dispatch(
            checkoutActions.couponCheck({
              error: true,
              message,
              coupon: couponCode,
              originalPrice,
              lock: false,
              discountedPrice: null,
              isCouponReferral: extra.isCouponReferral || null,
            }),
          );
        }
      }
    }
  };
};

export const onCoupon = (coupon, itemId, originalPrice, email, mobile, comboDiscount) => {
  return async (dispatch) => {
    const couponCode = coupon.toUpperCase();

    try {
      const { data } = await axios
        .get(`${api}/coupon/check`, {
          params: { coupon: couponCode, item_id: itemId, email, mobile, comboDiscount },
        })
        .catch((err) => {
          const errObj = err.response.data;
          throw new Error(
            JSON.stringify(
              customErrorObject(errObj.message, {
                isCouponReferral: errObj?.isReferral,
                type: errObj?.type,
                data: errObj?.data,
              }),
            ),
          );
        });

      const { error, message, discount, isReferral } = data;

      if (error) throw new Error(JSON.stringify(customErrorObject(message, { isCouponReferral: isReferral })));
      else {
        dispatch(
          checkoutActions.couponCheck({
            error: false,
            message,
            coupon: couponCode,
            originalPrice,
            discountedPrice: originalPrice - discount,
            lock: true,
            isCouponReferral: isReferral,
          }),
        );
      }
    } catch (err) {
      const parseError = JSON.parse(err.message);
      const { response } = parseError;

      if (response && response.data) {
        const { error, message, extra } = response.data;
        if (error) {
          if (extra.type === 'comboDiscountAvailable')
            dispatch(
              checkoutActions.couponCheck({
                error: true,
                coupon: couponCode,
                message,
                originalPrice,
                lock: false,
                discountedPrice: originalPrice - comboDiscount,
                isCouponReferral: extra.isCouponReferral || null,
              }),
            );
          else
            dispatch(
              checkoutActions.couponCheck({
                error: true,
                coupon: couponCode,
                message,
                originalPrice,
                lock: false,
                discountedPrice: null,
                isCouponReferral: extra.isCouponReferral || null,
              }),
            );
        }
      }
    }
  };
};

export const offCoupon = (price, comboDiscount) => {
  return (dispatch) => {
    dispatch(
      checkoutActions.couponCheck({
        coupon: '',
        message: '',
        originalPrice: price,
        discountedPrice: comboDiscount ? price - comboDiscount : null,
        error: false,
        lock: false,
        isCouponReferral: null,
      }),
    );
  };
};

export const marksItemInvalidConfig = (message) => {
  return (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: 'error',
        message: message,
      }),
    );
  };
};

export const onFinishCF = (
  source,
  medium,
  campaign,
  term,
  name,
  email,
  phone,
  itemId,
  country,
  state,
  city,
  coupon,
  lock,
  Class,
  marksItem,
  redirectUrl,
  countryCode,
  phoneCode,
  pg,
) => {
  return async (dispatch) => {
    try {
      dispatch(checkoutActions.toggleLoading(true));

      const {
        data: { error, payment },
      } = await axios.post(`${api}/order/payOther`, {
        source,
        medium,
        campaign,
        term,
        name,
        phone,
        phoneCode,
        item_id: itemId,
        country,
        countryCode,
        state: state || 'No State',
        city: city || 'No City',
        Class,
        email: lowerFirst(email),
        coupon: lock ? coupon.toUpperCase() : null,
        redirectUrl: marksItem ? redirectUrl : null,
      });

      if (error) throw new Error('Error creating order, try again!');
      else {
        if (pg === 'cashfree' || pg === 'cashfree_mathongo') {
          const cf = new window.Cashfree(payment?.cfSessionId);
          if (cf) cf.redirect();
        } else if (pg === 'ccavenue') {
          const { payload, iv } = payment?.ccaPayload;
          const symmetricKey = Buffer.from('YwecXlgkxxlZJozIRoUUnq6C6JT5jMLRP6C5mSzvdC4=', 'base64');

          const ivBuffer = Buffer.from(iv, 'base64');
          const decipher = crypto.createDecipheriv('aes-256-cbc', symmetricKey, Buffer.from(ivBuffer, 'base64'));
          let decryptedData = decipher.update(payload, 'base64', 'utf-8');
          decryptedData += decipher.final('utf-8');

          const parsedData = JSON.parse(decryptedData);
          dispatch(checkoutActions.updateCCAForm(parsedData));
        } else if (pg === 'phonepe') {
          // const ppLink = payment?.ppLink;
        }
      }
    } catch (error) {
      const errorResponse = error?.response?.data;
      if (errorResponse && errorResponse?.type === 'itemAlreadyPurchased') {
        dispatch(checkoutActions.toggleAlreadyPurchasedModal(true));
      } else if (errorResponse && errorResponse?.type === 'comboItemAlreadyPurchased') {
        const { comboItem, comboDiscount, itemPrice } = errorResponse?.data;
        const message = errorResponse?.message;
        dispatch(uiActions.showNotification({ status: 'info', message, duration: 6 }));
        dispatch(offCoupon(itemPrice));
        dispatch(checkoutActions.setComboItem({ comboItem, comboDiscount }));
      } else if (errorResponse && errorResponse?.message?.startsWith('customer_details.customer_phone')) {
        const message = 'Phone number invalid.';

        dispatch(uiActions.showNotification({ status: 'error', message }));
        dispatch(checkoutActions.setFocusField({ focusField: 'phone', focusFieldMessage: message }));
      } else dispatch(uiActions.showNotification({ status: 'error', message: 'Error creating order, try again!' }));
    }

    dispatch(checkoutActions.toggleLoading(false));
  };
};

// const onFinish = async () => {
// const paymentHandler = (order_id, name, email, phone) => {
//   const options = {
//     key: process.env.REACT_APP_RAZOR_KEY,
//     name: 'MathonGo by Scoremarks Pvt Ltd',
//     description: title,
//     prefill: {
//       name,
//       email,
//       contact: phone,
//     },
//     order_id,
//     handler: async (response) => {
//       try {
//         const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
//         const url = `${api}/order/checkout`;
//         const { data } = await Axios.post(url, {
//           razorpay_order_id,
//           razorpay_payment_id,
//           razorpay_signature,
//         });
//         window.location = `status?order_id=${data?.order?._id}`;
//       } catch (err) {
//         window.location = `status?order_id=${order_id}`;
//       }
//     },
//     modal: {
//       ondismiss: () => {
//         setLoading(false);
//       },
//     },
//     theme: {
//       color: '#686CFD',
//     },
//   };
//   const rzp1 = new window.Razorpay(options);
//   rzp1.open();
// };
//   try {
//     setLoading(true);
//     const { data } = await Axios.post(`${api}/order`, {
//       source,
//       medium,
//       campaign,
//       term,
//       name,
//       email,
//       phone,
//       item_id: _id,
//       coupon: lock ? couponCode.toUpperCase() : null,
//     });
//     const { error, order } = data;
//     if (error) {
//       cogoToast.error('Error creating order, try again', {
//         position: 'top-right',
//       });
//       setLoading(false);
//     } else {
//       paymentHandler(order.razorpay_order_id, name, email, phone);
//     }
//   } catch (e) {
//     cogoToast.error('Error creating order, try again', {
//       position: 'top-right',
//     });
//     setLoading(false);
//   }
// };
