import React from 'react';
import cogoToast from 'cogo-toast';
import { Layout, Menu } from 'antd';

const adminToken = localStorage.getItem('token_mg_payment');
const supportUserToken = localStorage.getItem('support_user_token');
const token = adminToken ? adminToken : supportUserToken;

const Header = (props) => {
  const { noLogout } = props;
  return (
    <Layout className="layout" style={{ margin: 0, padding: 0, minWidth: 0, overflowX: 'hidden' }}>
      <Layout.Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item onClick={() => (window.location = 'https://mathongo.com')}>
            <div>
              <img
                alt="logo-mathongo"
                style={{ width: '45%' }}
                src="https://cdn1.mathongo.com/wp-content/uploads/20190616134025/mathongo_full_logo_light.svg"
              />
            </div>
          </Menu.Item>
          {token && !noLogout ? (
            <Menu.Item
              style={{ float: 'right' }}
              onClick={() => {
                localStorage.removeItem('token_mg_payment');
                localStorage.removeItem('support_user_token');

                cogoToast
                  .success('Logging Out', {
                    position: 'top-right',
                  })
                  .then(() => {
                    window.location = '/';
                  });
              }}
            >
              Logout
            </Menu.Item>
          ) : null}
        </Menu>
      </Layout.Header>
    </Layout>
  );
};

export default Header;
