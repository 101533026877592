import { createSlice } from '@reduxjs/toolkit';

const homeSlice = createSlice({
  name: 'home',

  initialState: {
    item_id: null,
    coupon: null,
    utm: null,
    user: null,
  },

  reducers: {
    initialize(state, action) {
      state.item_id = action.payload.item_id;
      state.coupon = action.payload.coupon;
      state.utm = action.payload.utm;
      state.user = action.payload.user;
    },
  },
});

export const homeActions = homeSlice.actions;

export default homeSlice;
