import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import './App.css';

const Login = lazy(() => import('./pages/Login'));
const Page404 = lazy(() => import('./pages/404'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const StatusPage = lazy(() => import('./pages/Status'));

const App = () => {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/status" component={StatusPage} />

            <Route path="*">
              <Page404 type="404" />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
